<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Payments Configuration</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                
                <v-col
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Project</label>
                    <v-combobox
                        v-model="paymentsConfigurationRequest.serviceSelected"
                        :items="listService"
                        item-text="completeDescription"
                        item-value="id"
                        :rules=[validations.required]
                        clearable
                        single-line
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Description</label>
                    <v-text-field
                        v-model="paymentsConfigurationRequest.description" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Percentage</label>
                    <v-text-field
                        v-model="paymentsConfigurationRequest.percentage"
                        suffix=" %"
                        v-formatMoney="percFormat"
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Installment</label>
                    <v-text-field
                        v-model="paymentsConfigurationRequest.parcelNumber"
                        v-mask="'##'"
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <ActionButtons
                :request="paymentsConfigurationRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import { formaterDecimalBRServer } from '@/utilities/Utils';

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,

            paymentsConfigurationRequest: {
                id: 0,
                serviceSelected: null,
                description: "",
                percentage: 0,
                parcelNumber: 1,
            },

            percFormat: {
                suffix: '',
                thousands: '',
                decimal: '.',
                precision: 2,
            },

            listService: [],

            loading: false,
            
            validations: {
                required: required,
            }
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("servicePaymentsConfigurationModule/GetById", this.id);

                    if (response.success) {
                        this.paymentsConfigurationRequest = response.result;
                        this.paymentsConfigurationRequest.percentage = this.paymentsConfigurationRequest.percentageFormatted;
                    }

                }
            },

            async getLists() {
                this.listService = await this.$store.dispatch("serviceModule/List");
            },

            async cancel() {
                this.$router.push({ path: "/sales/paymentsConfigurationList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    this.paymentsConfigurationRequest.id = this.id;

                    if (this.paymentsConfigurationRequest.serviceSelected != null && 
                        this.paymentsConfigurationRequest.serviceSelected != undefined) {
                        this.paymentsConfigurationRequest.idService = this.paymentsConfigurationRequest.serviceSelected.id;
                    }

                    this.paymentsConfigurationRequest.percentage = formaterDecimalBRServer(this.paymentsConfigurationRequest.percentage); 

                    const result = await this.$store.dispatch("servicePaymentsConfigurationModule/CreateUpdate", this.paymentsConfigurationRequest);

                    if (result.success === true) {
                        this.showToast("success", "Success!", result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        async created() {
            this.showLoading();

            await this.getLists();
            this.getRegister();

            this.hideLoading();
        }
    })
</script>